import { createAsyncThunk } from '@reduxjs/toolkit';
import { NewsApi } from '../http';
import { INewsQuery } from '../models';

const newsApi = new NewsApi();

export const fetchNewsCatalog = createAsyncThunk(
  'news/catalog',
  async (body: INewsQuery, { rejectWithValue }) => {
    const payload: any = body;
    payload.offset = (Number(body.page) - 1) * Number(body.limit);
    delete payload.page;
    try {
      const data = await newsApi.findAll(payload);
      return { data, page: 1, limit: 9999, total: data.length };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
